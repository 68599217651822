import BlogBody from "../components/BlogBody";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogCard from "../components/BlogCard";

const BlogPage = () => {
  return (
    <>
      <Navbar />
      <BlogBody />
      <BlogCard />
      <Footer />
    </>
  );
};

export default BlogPage;
