
import React from "react";
import { Button, Flex } from "@chakra-ui/react";

const AnnouncementsPagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Flex justifyContent="center" mt="20px" mb="20px">
      <Button
        mx="1"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt;
      </Button>
      <Button mx="1">{currentPage}</Button>
      <Button
        mx="1"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &gt;
      </Button>
    </Flex>
  );
};

export default AnnouncementsPagination;
