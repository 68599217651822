import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BlogCardItem = ({
  announcement,
  uncleanedContent,
  hoveredIndex,
  setHoveredIndex,
  index,
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/blog_page/${announcement.id}`}
      state={{
        announcementId: announcement.id,
        announcementTitle: announcement.title,
        announcementDate: announcement.date,
        uncleanedContent,
      }}
      textDecoration="none"
      _hover={{ textDecoration: "none" }}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <Box
        mx="auto"
        overflow="hidden"
        position="relative"
        width="95%"
        maxH="100%"
        borderRadius="md"
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.5)"
        transition="transform 0.3s"
        _hover={{ transform: "scale(1.05)" }}
      >
        <Image
          src={announcement.imageUrl}
          alt={t("announcement_image")}
          objectFit="cover"
          width="100%"
          height="250px"
          opacity="0.9"
          filter="auto"
          brightness="50%"
        />
        <Box
          p="4"
          pos="absolute"
          bottom="0"
          left="0"
          right="0"
          textAlign="left"
        >
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="whiteColor"
            mb="2"
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.8)"
          >
            {announcement.title}
          </Text>
          <Text
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.8)"
            fontSize="md"
            color="whiteColor"
            noOfLines={2}
          >
            {hoveredIndex === index && announcement.content}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};
export default BlogCardItem;
