import React from "react";
import { Navigate } from "react-router-dom";
import AdminForgotModal from "../components/AdminForgotModal";
import { useAuthToken } from "../utils/hooks";

const PrivateRoute = () => {
  const token = useAuthToken();
  if (!token) {
    return <Navigate to="/admin" />;
  }
  return <AdminForgotModal />;
};
export default PrivateRoute;
