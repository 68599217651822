import React, { createContext, useContext, useState } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const value = {
    userRole,
    setUserRole,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserRole = () => useContext(UserContext);
