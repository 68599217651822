import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {blogImage} from "../utils/consts"
const BlogBody = (props) => {
  const { t } = useTranslation();
  const { bgColor, textColor } = props;

  return (
    <Flex
    bgImage={`url(${blogImage.image})`}
    bgSize={{
        base: "100% 50%",
        sm: "100% 80%",
        md: "100% 90%",
        lg: "100% 100%",
      }}
      bgRepeat="no-repeat"
      width="100vw"
      height="100vh"
    >
      <Box bgColor={bgColor} textColor={textColor}>
        <Box>
          <Text
            textAlign="left"
            mt={{ base: "100px", sm: "150px", md: "200px", lg: "180px" }}
            ml="10"
            color="whiteColor"
            className="font-bold"
            fontSize={{
              base: "2xl",
              sm: "3xl",
              md: "4xl",
              lg: "5xl",
              xl: "5xl",
            }}
          >
            {t("blog_title")}
          </Text>
          <Text
            textAlign="left"
            ml="8"
            color="whiteColor"
            fontSize={{ base: "md", sm: "md", md: "xl", lg: "4xl", xl: "4xl" }}
          >
            {t("blog_subtitle")}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default BlogBody;
