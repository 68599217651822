import React from "react";
import BodyBox from "../components/BodyBox";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PrivacyPolicyBody from "../components/PrivacyPolicyBody";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <BodyBox
        title="privacy_policy"
        bgColor="primaryColor"
        textColor="whiteColor"
      />
      <PrivacyPolicyBody />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
