import React from "react";
import Body from "../components/Body";
import Brand from "../components/Brand";
import Footer from "../components/Footer";
import FlipCard from "../components/Flipcard";
import Navbar from "../components/Navbar";
import Process from "../components/services_components/ServicesProcess";
import { processCardData } from "../utils/consts";
import SwiperSlider from "../components/SwiperSlider";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <Body />
      <FlipCard />
      <SwiperSlider />
      <Process title="contact_us" mapList={processCardData} />
      <Brand />
      <Footer />
    </>
  );
};

export default HomePage;
