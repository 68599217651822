import React, { useCallback } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PasswordResetFormValidation } from "../utils/validations";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import { useAdminForgotModal, useAuthToken } from "../utils/hooks";

const AdminForgotModal = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useAdminForgotModal();
  const token = useAuthToken();

  const handlePasswordReset = useCallback(
    async (values, actions) => {
      try {
        const data = await mutateAsync(values);
        if (data.success) {
          toast({
            title: t("success"),
            description: t("update_successful"),
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate("/admin_panel");
        } else {
          toast({
            title: t("error"),
            description: data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: t("error"),
          description: t("unsuccessful"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      actions.setSubmitting(false);
    },
    [mutateAsync, navigate, toast]
  );

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box
        p={8}
        maxWidth="400px"
        width="100%"
        borderRadius="10px"
        boxShadow="lg"
      >
        <Stack align="center" margin="20px">
          <Logo />
        </Stack>
        <VStack spacing={4} align="stretch">
          <Formik
            initialValues={{
              token: token,
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={PasswordResetFormValidation()}
            onSubmit={handlePasswordReset}
          >
            {({ errors, touched }) => (
              <Form>
                <Field name="newPassword">
                  {({ field }) => (
                    <FormControl
                      isInvalid={errors.newPassword && touched.newPassword}
                    >
                      <FormLabel htmlFor="newPassword">
                        {t("new_password")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="newPassword"
                        type="password"
                        width="full"
                      />
                      <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="confirmPassword">
                  {({ field }) => (
                    <FormControl
                      isInvalid={
                        errors.confirmPassword && touched.confirmPassword
                      }
                    >
                      <FormLabel htmlFor="confirmPassword">
                        {t("confirm_new_password")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="confirmPassword"
                        type="password"
                        width="full"
                      />
                      <FormErrorMessage>
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  bg="logoRedColor"
                  color="whiteColor"
                  isLoading={isLoading}
                  type="submit"
                  width="full"
                >
                  {t("update_password")}
                </Button>
              </Form>
            )}
          </Formik>
        </VStack>
      </Box>
    </Flex>
  );
};

export default AdminForgotModal;
