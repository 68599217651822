import { Flex, Grid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import blogCardBackground from "../assets/blogCardBackground.jpg";
import { HTML_TAG_REGEX, perPage } from "../utils/consts.js";
import BlogCardItem from "./BlogCardItem";
import { extractImageFromHTML } from "../utils/helpers.js";
import { apiClient } from "../api/apiClient.js";
import { Button } from "react-scroll";

function cleanHtmlTags(html) {
  return html.replace(HTML_TAG_REGEX, "");
}

const fetchAnnouncements = async ({ pageParam = 1 }) => {
  const response = await apiClient.get(
    `/CombinedAnnouncements.php?page=${pageParam}&per_page=${perPage}`
  );
  const { data } = response.data;
  return {
    announcements: data.AnnouncementsList.map((announcement) => ({
      ...announcement,
      content: cleanHtmlTags(announcement.content),
      imageUrl:
        extractImageFromHTML(announcement.content) || blogCardBackground,
    })),
    nextPage: pageParam + 1,
    totalPages: Math.ceil(data.totalAnnouncements / perPage),
  };
};

const BlogCard = () => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery("announcements", fetchAnnouncements, {
      getNextPageParam: (lastPage) =>
        lastPage.nextPage <= lastPage.totalPages
          ? lastPage.nextPage
          : undefined,
    });

  return (
    <>
      {isSuccess && data.pages[0].announcements.length > 0 ? (
        <>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
            gap={6}
            mb="20px"
          >
            {data.pages.map((page) =>
              page.announcements.map((announcement, index) => (
                <BlogCardItem
                  key={announcement.id}
                  announcement={announcement}
                  uncleanedContent={announcement.content}
                  hoveredIndex={hoveredIndex}
                  setHoveredIndex={setHoveredIndex}
                  index={index}
                />
              ))
            )}
          </Grid>
          {hasNextPage && (
            <Flex
              justifyContent="center"
              mt="30px"
              mb="50px"
              fontSize="18px"
              fontWeight="600"
            >
              <Button
                onClick={() => fetchNextPage()}
                isLoading={isFetchingNextPage}
                loadingText={t("loading_more")}
                disabled={isFetchingNextPage}
              >
                {isFetchingNextPage ? t("loading_more") : t("load_more")}
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Text>{t("no_announcements_available")}</Text>
      )}
    </>
  );
};

export default BlogCard;
