import React, { useState, useCallback } from "react";
import { Box, Text, CardBody, Card, SimpleGrid, Icon } from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { modalCardLists } from "../utils/consts";
import ModalHiddenBox from "./ModalHiddenBox";

const Modal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const openModal = selected && (
    <ModalHiddenBox
      title={modalCardLists[selected].hiddenTitle}
      buttons={modalCardLists[selected].hiddenButtons}
    />
  );
  const resetSelected = useCallback(() => {
    setSelected(null);
  }, []);
  if (!visible) return null;

  return (
    <Box
      className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
      zIndex={2}
    >
      <Box
        className="bg-white p-2 rounded w-2/4 h-3/5 overflow-y-auto"
        height={{ base: "80%", sm: "80%", md: "60%", lg: "70%" }}
        width={{ base: "100%", sm: "100%", md: "70%", lg: "60%" }}
      >
        <IoMdClose
          onClick={() => {
            onClose();
            resetSelected();
          }}
          size="40px"
          style={{ marginLeft: "auto" }}
        />
        <Text className="font-semibold text-center text-xl text-gary-700">
          {t("offer_form")}
        </Text>
        <Text className="text-center mb-5">{t("select_service")}</Text>
        <SimpleGrid
          margin={5}
          spacing={2}
          justifyItems={"center"}
          templateColumns={{
            base: "1fr",
            sm: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr",
            xl: "1fr 1fr 1fr 1fr",
          }}
          templateRows={{
            base: "1fr 1fr 1fr 1fr",
            sm: "1fr 1fr 1fr 1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr",
            xl: "1fr",
          }}
        >
          {modalCardLists.map(({ id, title, info, icon }, index) => (
            <Card
              key={index}
              width="200px"
              height="250px"
              mt="30px"
              bgColor="gray.100"
              onClick={() => setSelected(id)}
              cursor="pointer"
              borderWidth={id === selected ? "1px" : "0px"}
              borderColor={id === selected ? "blueColor" : "transparent"}
              _hover={{
                borderColor: "blueColor",
                borderWidth: "2px",
              }}
            >
              <CardBody className="text-center">
                <Icon
                  className="rounded-full"
                  width="40px"
                  height="40px"
                  padding={2.5}
                  bgColor="gray.300"
                  as={icon}
                />
                <Text
                  className="font-semibold"
                  fontSize="15px"
                  marginTop="30px"
                  marginBottom="5px"
                >
                  {t(title)}
                </Text>
                <Text fontSize="12px">{t(info)}</Text>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
        {openModal}
      </Box>
    </Box>
  );
};

export default Modal;
