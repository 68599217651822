import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { updateAnnouncement, createAnnouncement } from "../api/apiService";
import { currentDate } from "../utils/consts.js";

const AnnouncementModal = ({ isOpen, onClose, updateData, editData }) => {
  const initialAnnouncement = {
    date: currentDate,
    title: "",
    content: "",
  };
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [newAnnouncement, setNewAnnouncement] = useState(initialAnnouncement);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (editData) {
      setNewAnnouncement(editData);
    } else {
      setNewAnnouncement(initialAnnouncement);
    }
    setMessage("");
  }, [editData, isOpen]);

  const mutation = useMutation(
    async () => {
      if (editData?.id) {
        const updatedAnnouncement = { ...newAnnouncement, id: editData.id };
        await updateAnnouncement(updatedAnnouncement);
        setMessage(t("update_successful"));

        updateData((prevData) =>
          prevData.map((announcement) =>
            announcement.id === editData.id ? updatedAnnouncement : announcement
          )
        );
      } else {
        await createAnnouncement(newAnnouncement);
        setMessage(t("save_successful"));
      }
      queryClient.invalidateQueries("announcements");
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("announcements");
      },
      onError: (error) => {
        setMessage(t("error_message") + error.message);
      },
    }
  );
  const handleClose = useCallback(() => {
    setNewAnnouncement(initialAnnouncement);
    onClose();
  }, [initialAnnouncement, onClose]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    mutation.mutate();
    onClose();
  }, [mutation, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="80%"
        maxW="800px"
        padding="25"
        flexDirection="column"
      >
        <ModalHeader mt="10">
          {editData ? t("update_announcement") : t("add_new_announcement")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          gap={4}
        >
          <Input
            type="date"
            name="date"
            defaultValue={currentDate}
            onChange={handleInputChange}
            readOnly
          />
          <Input
            name="title"
            placeholder={t("title")}
            value={newAnnouncement.title}
            onChange={handleInputChange}
            width="100%"
            padding={4}
          />
          <ReactQuill
            name="content"
            style={{ width: "100%", height: "200px" }}
            value={newAnnouncement.content}
            onChange={(value) =>
              setNewAnnouncement({ ...newAnnouncement, content: value })
            }
            modules={{
              toolbar: {
                container: [
                  ["bold", "italic", "underline", "strike"],
                  [{ header: "1" }, { header: "2" }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  [{ align: [] }],
                ],
              },
            }}
          />
          <Button
            bg="baseColor"
            color="whiteColor"
            mt={16}
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
          >
            {editData ? t("update") : t("save")}
          </Button>
          <Text color={message.includes("error") ? "red" : "green"}>
            {message}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AnnouncementModal;
