import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import {
  Box,
  Image,
  SimpleGrid,
  List,
  ListItem,
  Button,
  Text,
} from "@chakra-ui/react";
import { brandSliderCard } from "../utils/consts";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SwiperSlider = () => {
  const { t } = useTranslation();

  return (
    <div id="reference">
      <Text
        variant={{
          base: "size5",
          sm: "size6",
          md: "size6",
          lg: "size7",
          xl: "size8",
        }}
        color="gray.400"
        _hover={{ color: "buttonColor" }}
        textAlign="center"
      >
        {t("references")}
      </Text>
      <Box
        zIndex={1}
        borderRadius="2xl"
        bgColor="primaryColor"
        marginTop="60px"
      >
        <Swiper
          border-radius="15px !important"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation
          modules={[Autoplay, Pagination, Navigation]}
        >
          {brandSliderCard.map(
            (
              { image, title, text, customer, person, service, software, link },
              index
            ) => (
              <SwiperSlide key={index}>
                <SimpleGrid
                  alignItems="center"
                  templateColumns={{
                    base: "minmax(100%)",
                    sm: "minmax(100%)",
                    md: "minmax(100%)",
                    lg: "4fr 3fr",
                    xl: "4fr 3fr",
                  }}
                >
                  <Box
                    padding={{
                      sm: "30px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                  >
                    <Image alignItems="center" src={image} alt="#" />
                  </Box>
                  <List
                    src={image}
                    spacing={{ sm: "2", md: "5", lg: "5", xl: "7" }}
                    padding={{
                      sm: "30px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                    w={{
                      sm: "345px",
                      md: "768px",
                      lg: "450px",
                      xl: "618px",
                    }}
                    style={{ margin: "0 auto" }}
                  >
                    <ListItem
                      color="white"
                      fontSize={{
                        sm: "25px",
                        md: "40px",
                        lg: "30px",
                        xl: "40px",
                      }}
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {t(title)}
                    </ListItem>
                    <Text
                      marginLeft={0}
                      color="white"
                      fontSize={{
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                        xl: "17px",
                      }}
                    >
                      {t(text)}
                    </Text>
                    <Text
                      marginLeft={0}
                      color="white"
                      fontSize={{
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                        xl: "17px",
                      }}
                    >
                      {t(customer)} {person}
                    </Text>
                    <Text
                      marginLeft={0}
                      color="white"
                      fontSize={{
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                        xl: "17px",
                      }}
                    >
                      {t(service)}: {t(software)}
                    </Text>
                    <Button
                      w={{
                        sm: "270px",
                        md: "680px",
                        lg: "350px",
                        xl: "518px",
                      }}
                      fontSize={{
                        sm: "10px",
                        md: "16px",
                        lg: "15px",
                        xl: "20px",
                      }}
                      variant="solid"
                      bg="white"
                      color="black"
                      borderRadius="2xl"
                      colorScheme="blue"
                      as="a"
                      href={link}
                      target="_blank"
                    >
                      {t("go_site")}
                    </Button>
                  </List>
                </SimpleGrid>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Box>
    </div>
  );
};

export default SwiperSlider;
