import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { software_dev } from "../../utils/consts";

const SoftwareDev = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="software_development_for_corporates"
        headerSubtitle="software_development_discover"
      />
      <ServicesBody
        headerText="software_dev_solution"
        headerSubtitle="software_dev_process"
        contentText="software_dev_text"
        servicesStageList={software_dev}
      />
      <Footer />
    </>
  );
};

export default SoftwareDev;
