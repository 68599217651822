import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import Process from "../../components/services_components/ServicesProcess";
import {
  corporate_id,
  corporatedProcess
} from "../../utils/consts";

const CorporateId = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="corporate_identity_studies"
        headerSubtitle="corporate_identity_discover"
      />
      <ServicesBody
        headerText="corporateid_solution"
        headerSubtitle="corporateid_process"
        contentText="corporateid_text"
        servicesStageList={corporate_id}
      />
      <Process
        title="software_process"
        mapList={corporatedProcess}
        page="cloudsolution"
      />
      <Footer />
    </>
  );
};

export default CorporateId;
