import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const KvkkPolicyBody = () => {
  const { t } = useTranslation();

  return (
    <Box marginBottom="100px">
      <Text
        fontSize={{ base: "2xl", sm: "2xl", md: "3xl" }}
        fontWeight="bold"
        paddingTop={{ md: "6", mt: "20" }}
        margin={{ base: "3rem ", sm: "2rem", md: "2rem" }}
      />
      <Box display="flex" margin={{ base: "3rem ", sm: "2rem", md: "2rem" }}>
        <Text>{t("kvkk_policy_text")}</Text>
      </Box>
    </Box>
  );
};

export default KvkkPolicyBody;
