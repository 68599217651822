import React from "react";
import BodyBox from "../components/BodyBox";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import KvkkPolicyBody from "../components/KvkkPolicyBody";

const KvkkPolicy = () => {
  return (
    <>
      <Navbar />
      <BodyBox title="kvkk" bgColor="primaryColor" textColor="whiteColor" />
      <KvkkPolicyBody />
      <Footer />
    </>
  );
};

export default KvkkPolicy;
