import { urls } from "../utils/urls";
import { apiClient } from "../api/apiClient";

export const sendContactApi = async (formData) => 
  await apiClient
    .post(urls.contact, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);


export const sendModelBoxApi = async (formData) => 
  await apiClient
    .post(urls.modelBox, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);


export const sendCareerApi = async (formData) => 
   await apiClient
    .post(urls.career, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
