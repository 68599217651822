import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import {
  adminLogin,
  registerAdmin,
  adminForgotModal,
  adminForgotPassword,
  adminModalPassword,
} from "../api/apiService";
import { useLocation } from "react-router-dom";
export const useAdminLogin = () => {
  const { isLoading, mutateAsync } = useMutation(adminLogin);
  return { isLoading, mutateAsync };
};
export const useRegisterAdmin = () => {
  const { isLoading, mutateAsync } = useMutation(registerAdmin);
  return { isLoading, mutateAsync };
};
export const useAdminForgotModal = () => {
  const { isLoading, mutateAsync } = useMutation(adminForgotModal);
  return { isLoading, mutateAsync };
};

export const useAdminForgotPassword = () => {
  const { isLoading, mutateAsync } = useMutation(adminForgotPassword);
  return { isLoading, mutateAsync };
};
export const useAdminModalPassword = () => {
  const { isLoading, mutateAsync } = useMutation(adminModalPassword);
  return { isLoading, mutateAsync };
};
export const useMediaQuery = () => {
  const [show, setShow] = useState({});

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShow({
        width: document.body.clientWidth,
      });
    });
  }, []);

  return show;
};

export const useAuthToken = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  return token;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};
