import { Image, Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { brandData } from "../utils/consts";
import { theme } from "../utils/theme";
const Brand = () => {
  return (
    <Box mx="auto" marginTop="150px" width="100%">
      <SimpleGrid columns={{ xl: 1, l: 1, md: 2 }}>
        <Box
          height="150px"
          backgroundColor="brandColor"
          border={`0.2rem solid ${theme.colors.brandColor}`}
        >
          <Flex
            marginTop="50px"
            justifyContent="space-around"
            flexWrap="wrap"
            alignItems="center"
            gap={1}
          >
            {brandData.map((item, i) => (
              <Box key={i}>
                <Image
                  w={{ base: "80px", sm: "110px", md: "140px", lg: "200px" }}
                  src={item}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Brand;
