import React from "react";
import BodyBox from "../components/BodyBox";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CareerBody from "../components/CareerBody";

const CareerPage = () => {
  return (
    <>
      <Navbar />
      <BodyBox title="career" bgColor="primaryColor" textColor="whiteColor" />
      <CareerBody />
      <Footer />
    </>
  );
};

export default CareerPage;
