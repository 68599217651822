import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/loginadmin.png";
import { useRegisterAdmin } from "../utils/hooks";
import Logo from "./Logo";
import { getCookie } from "./Token";
import { validationSchema } from "../utils/validations";
const AdminRegister = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { mutateAsync, isLoading, isError, error } = useRegisterAdmin();
  const authToken = getCookie("authToken") || "";

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const data = await mutateAsync({ values, authToken });
        toast({
          title: data.success
            ? t("registration_successful")
            : t("email_available"),
          status: data.success ? "success" : "error",
          duration: 5000,
          isClosable: true,
        });
      } catch (err) {
        toast({
          title: t("error_message"),
          description: err.message || t("error_message"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [authToken, mutateAsync, t, toast]
  );

  return (
    <Box display="flex">
      <Image src={logo} w="600px" h="730px" />
      <Flex minH="100vh" align="center" justify="center">
        <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
          <Stack align="center">
            <Logo />
          </Stack>
          <Box width="450px" rounded="lg" boxShadow="lg" p={8}>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema(t)}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="email" isInvalid={errors.email && touched.email}>
                      <FormLabel>{t("email")}</FormLabel>
                      <Field type="email" name="email" as={Input} />
                      {errors.email && touched.email && (
                        <Box color="red">{errors.email}</Box>
                      )}
                    </FormControl>
                    <FormControl id="password" isInvalid={errors.password && touched.password}>
                      <FormLabel>{t("password")}</FormLabel>
                      <Field type="password" name="password" as={Input} />
                      {errors.password && touched.password && (
                        <Box color="red">{errors.password}</Box>
                      )}
                    </FormControl>
                    <Button
                      mt={4}
                      width="full"
                      type="submit"
                      isLoading={isSubmitting || isLoading}
                      bg="buttonColor"
                      color="white"
                      _hover={{
                        bg: "primaryColor"
                      }}
                    >
                      {t("sign_up")}
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
          {isError && (
            <Box textAlign="center" fontSize="sm" color="red">
              {error.message}
            </Box>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default AdminRegister;
