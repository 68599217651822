import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import ServicesBody from "../../components/services_components/ServicesBody";
import {android_app } from "../../utils/consts";


const AndroidApp = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="android_mobil_app"
        headerSubtitle="android_app_discover"
      />
      <ServicesBody
        headerText="android_solution"
        headerSubtitle="android_process"
        contentText="android_text"
        servicesStageList={android_app}
      />
      <Footer />
    </>
  );
};

export default AndroidApp;
