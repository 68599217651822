import React, { useState } from "react";
import {
  Box,
  Text,
  Container,
  SimpleGrid,
  Heading,
  Stack,
  Input,
  Button,
  Textarea,
  Select,
  ButtonGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  careerFormOption,
  careerFormValue,
  careerFormList,
} from "../utils/consts";
import { Formik, Form } from "formik";
import { CarrerFormValidation } from "../utils/validations";
import FormikFormValidation from "./FormikFormValidation";
import { sendCareerApi } from "../api/api";
import CustomAlert from "../components/CustomAlert";

const CareerBody = () => {
  const { t } = useTranslation();
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values, { resetForm, setFieldValue }) => {
    const formData = new FormData();
    if (values.file) {
      formData.append("file", values.file, values.file.name);
    }
    setSubmissionStatus("");
    const requestData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      description: values.description,
      position: t(values.position),
    };
    setIsSubmitting(true);
    resetForm();
    setFieldValue("message", "");
    for (const key in requestData) {
      formData.append(key, requestData[key]);
    }
    const response = await sendCareerApi(formData);
    if (response) {
      setSubmissionStatus("success");
    } else {
      setSubmissionStatus("error");
    }
    setIsSubmitting(false);
  };
  const isFileValid = (file) => {
    if (!file) return true;
    const allowedExtensions = ["pdf", "txt", "doc", "png", "jpg", "jpeg"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      alert(t("invalid_file_format"));
      return false;
    }
    const maxSizeMB = 6;
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      alert(t("invalid_file_size"));
      return false;
    }
    return true;
  };
  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (isFileValid(file)) {
      setFieldValue("file", file);
    } else {
      event.target.value = null;
    }
  };

  return (
    <Box className="relative">
      <Container
        as={SimpleGrid}
        maxW="8xl"
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 0, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "6xl" }}
          >
            {t("teammates")}
          </Heading>
          <Text
            marginTop="10px"
            fontFamily="heading"
            fontSize={{ base: "1xl", md: "1xl" }}
          >
            {t("career_content")}
          </Text>
          <Stack
            bg="gray.50"
            rounded="xl"
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
          >
            <Text>{t("career_intern_job")}</Text>
          </Stack>
        </Stack>
        <Stack
          bg="gray.50"
          rounded="xl"
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
        >
          <Stack spacing={4}>
            <Heading
              color="gray-800"
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {t("join_team")}
            </Heading>
            <Text color="gray.500" fontSize={{ base: "sm", sm: "md" }}>
              {t("career_form_info")}
            </Text>
          </Stack>
          <Formik
            initialValues={careerFormValue}
            validationSchema={CarrerFormValidation()}
            onSubmit={handleSubmit}
          >
            {({ values, errors, handleChange, touched, setFieldValue }) => (
              <Form>
                <Stack spacing={4}>
                  {careerFormList.map(({ id, title, type, place }, index) => (
                    <FormikFormValidation
                      key={index}
                      id={id}
                      title={title}
                      type={type}
                      place={place}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                    />
                  ))}
                  <Select
                    id="position"
                    value={values.position}
                    onChange={handleChange}
                    bg="gray.100"
                    placeholder={t("position_select")}
                  >
                    {careerFormOption.map(({ title }, index) => (
                      <option key={index} value={title}>
                        {t(title)}
                      </option>
                    ))}
                  </Select>
                  {errors.position && touched.position && (
                    <Text as="sub" color="baseRedColor">
                      {errors.position}
                    </Text>
                  )}
                  <Textarea
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    resize="none"
                    placeholder={t("get_to_know_you")}
                  />
                  {errors.description && touched.description && (
                    <Text as="sub" color="baseRedColor">
                      {errors.description}
                    </Text>
                  )}
                  <ButtonGroup>
                    <Input
                      type="file"
                      name="file"
                      multiple
                      onChange={(event) =>
                        handleFileChange(event, setFieldValue)
                      }
                    />
                    <Button fontFamily="heading" bg="gray.200" color="gray.800">
                      {t("upload_cv")}
                    </Button>
                  </ButtonGroup>
                  <Text as="sub">{t("upload_cv_content")}</Text>
                </Stack>
                <Button
                  type="submit"
                  fontFamily="heading"
                  mt={8}
                  w="full"
                  bgColor="baseRedColor"
                  color="whiteColor"
                  _hover={{
                    boxShadow: "xl",
                  }}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  {isSubmitting ? t("submitting") : t("submit_form")}
                </Button>
                <Stack spacing={4} align="center">
                  {submissionStatus && (
                    <CustomAlert
                      submissionStatus={submissionStatus}
                      successText={"form_submit"}
                      errorText={"form_failed"}
                    />
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Container>
    </Box>
  );
};

export default CareerBody;
