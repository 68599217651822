import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { BsChevronDoubleDown } from "react-icons/bs";

const ServicesHeader = ({ headerText, headerSubtitle }) => {
  const { t } = useTranslation();

  return (
    <Box bgColor="primaryColor" textColor="whiteColor">
      <Box className="max-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <Text className="md:text-6xl sm:text-5xl text-4xl font-bold md:py-6 mt-20">
          {t(headerText)}
        </Text>
        <Text className="md:text-3xl font-bold  mt-10">
          {t(headerSubtitle)}
        </Text>
        <Link
          to="body"
          smooth
          className="my-6 mx-auto py-3 mb-3 mt-20 cursor-pointer"
        >
          <BsChevronDoubleDown color="whiteColor" size={50} />
        </Link>
      </Box>
    </Box>
  );
};

export default ServicesHeader;
