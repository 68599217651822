import React from "react";
import { Box, Text} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const BodyBox = (props) => {
  const { t } = useTranslation();
  const { title, bgColor, textColor } = props;

  return (
    <Box bgColor={bgColor} textColor={textColor}>
      <Box className="max-w-[600px] w-full h-[320px] text-center mx-auto flex flex-col justify-center">
        <Text
          className="font-bold"
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
        >
          {t(title)}
        </Text>
      </Box>
    </Box>
  );
};

export default BodyBox;
