import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import Process from "../../components/services_components/ServicesProcess";
import { support_care, supportProcess } from "../../utils/consts";

const SupportCare = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="maintenance_support"
        headerSubtitle="maintenance_support_discover"
      />
      <ServicesBody
        headerText="support_care_solution"
        headerSubtitle="support_care_process"
        contentText="support_text"
        servicesStageList={support_care}
      />
      <Process
        title="software_process"
        mapList={supportProcess}
        page="cloudsolution"
      />
      <Footer />
    </>
  );
};

export default SupportCare;
