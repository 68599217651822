import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { cloud_solution } from "../../utils/consts";

const CloudSolution = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="cloud_solution"
        headerSubtitle="cloud_solutions_discover"
      />
      <ServicesBody
        headerText="cloud_solutions"
        headerSubtitle="cloud_process"
        contentText="cloud_text"
        servicesStageList={cloud_solution}
      />
      <Footer />
    </>
  );
};

export default CloudSolution;
