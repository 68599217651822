import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserDetailsFromCookie, isAdmin } from "../utils/helpers";

const PrivateRouter = () => {
  const userDetails = getUserDetailsFromCookie();
  const isUserAdmin = userDetails ? isAdmin(userDetails.role) : false;
  return isUserAdmin ? <Outlet /> : <Navigate to="/admin" />;
};
export default PrivateRouter;
