import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAdminForgotPassword } from "../utils/hooks";

const AdminForgotPasswordModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const toast = useToast();
  const { mutateAsync, isLoading } = useAdminForgotPassword();
  const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);

  const handlePasswordReset = useCallback(async () => {
    try {
      const data = await mutateAsync({email});
      if (data.success) {
        toast({
          title: t("success"),
          description: t("send_email_message"),
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("error"),
          description: data.message || t("send_email_error"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      onClose();
    } catch (error) {
      toast({
        title: t("error"),
        description: t("send_email_error"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [mutateAsync, email, onClose, toast, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("password_reset")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>{t("email_adress_reset")}</FormLabel>
            <Input type="email" value={email} onChange={handleEmailChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="blueColor"
            color="whiteColor"
            isLoading={isLoading}
            mr={3}
            onClick={handlePasswordReset}
          >
            {t("send_reset_link")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdminForgotPasswordModal;
