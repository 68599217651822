import React, { useState } from "react";
import { Flex, Box, FormControl, FormLabel, Input, Stack, Button, Image, useToast, Text } from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import logo from "../assets/loginadmin.png";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { setCookie } from "./Token.js";
import { useUserRole } from "../context/userRole.js";
import AdminForgotPasswordModal from "./AdminForgotPassword.js";
import { useTranslation } from "react-i18next";
import { useAdminLogin } from "../utils/hooks.js";
import { saveAuthUserToCookie } from "../utils/helpers.js";
import { validationSchema } from "../utils/validations.js"; 

const AdminLogin = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const { mutateAsync, isLoading } = useAdminLogin();
  const navigate = useNavigate();
  const { setUserRole } = useUserRole();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema(t)}
      onSubmit={async (values, actions) => {
        try {
          const data = await mutateAsync({ values });
          setUserRole(data?.role);
          if (data.success) {
            setCookie("authToken", data.token);
            saveAuthUserToCookie(data);
            navigate("/admin_panel");
          } else {
            toast({
              title: t("email_password_incompatible"),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: t("error_message"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        actions.setSubmitting(false);
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <Box display="flex">
          <Flex
            minH="100vh"
            align="center"
            justify="center"
            direction={{ base: "column", md: "row" }}
          >
            <Image src={logo} w={{ base: "100%", md: "600px" }} h="100vh" />
            <Stack
              spacing={8}
              mx="auto"
              maxW="lg"
              py={12}
              px={6}
              order={{ base: 1, md: 2 }}
            >
              <Stack align="center">
                <Logo />
              </Stack>
              <Box width={{ base: "100%", md: "450px" }} rounded="lg" boxShadow="lg" p={8}>
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={4}>
                    <FormControl id="email" isInvalid={touched.email && errors.email}>
                      <FormLabel>{t("email")}</FormLabel>
                      <Field as={Input} type="email" name="email" />
                      {touched.email && errors.email && <Text color="red.500">{errors.email}</Text>}
                    </FormControl>
                    <FormControl id="password" isInvalid={touched.password && errors.password}>
                      <FormLabel>{t("password")}</FormLabel>
                      <Field as={Input} type="password" name="password" />
                      {touched.password && errors.password && <Text color="red.500">{errors.password}</Text>}
                    </FormControl>

                    <Text
                      color="baseColor"
                      onClick={() => setIsResetModalOpen(true)}
                      _hover={{ textDecoration: "underline" }}
                    >
                      {t("forget_your_password")}
                    </Text>
                    {isResetModalOpen && (
                      <AdminForgotPasswordModal
                        isOpen={isResetModalOpen}
                        onClose={() => setIsResetModalOpen((prev) => !prev)}
                      />
                    )}
                    <Button
                      type="submit"
                      bg="buttonBlueColor"
                      color="whiteColor"
                      _hover={{
                        bg: "buttonColor",
                      }}
                      isLoading={isLoading}
                    >
                      {t("sign_in")}
                    </Button>
                  </Stack>
                </Form>
              </Box>
            </Stack>
          </Flex>
        </Box>
      )}
    </Formik>
  );
};

export default AdminLogin;
