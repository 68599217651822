import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Box, Heading, Text, Divider, Flex, Image } from "@chakra-ui/react";
import blogBackgroundPhoto from "../assets/blogBackgroundPhoto.jpg";
import { useTranslation } from "react-i18next";
import { IMG_SRC_REGEX } from "../utils/consts";
import { extractImageFromHTML } from "../utils/helpers";

const BlogDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { announcementDate, uncleanedContent, announcementTitle } =
    location.state;

  const imageUrl = useMemo(
    () => extractImageFromHTML(uncleanedContent),
    [uncleanedContent]
  );

  const contentWithoutImage = useMemo(
    () => uncleanedContent.replace(IMG_SRC_REGEX, ""),
    [uncleanedContent]
  );

  return (
    <Flex bgImage={`url(${blogBackgroundPhoto})`} minHeight="100vh">
      <Box mt="40" p="4" width="70%" mx="auto" bg="white" mb="5">
        <Heading mb="4">{announcementTitle}</Heading>
        <Text mb="2" textAlign="left">
          {`${t("publication_date")}: ${announcementDate}`}
        </Text>
        <Divider mb="4" />
        {imageUrl && (
          <Image
            src={imageUrl}
            alt={t("announcement_image")}
            objectFit="cover"
            width="100%"
            display="block"
            marginLeft="auto"
            marginRight="auto"
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: contentWithoutImage }} />
      </Box>
    </Flex>
  );
};

export default BlogDetail;
