import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import tr from "../assets/turkish.png";
import { useTranslation } from "react-i18next";
import { languageOptions } from "../utils/consts";

const LanguageOption = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(tr);
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage") || "tr";
    setLang(languageOptions.get(savedLanguage));
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);
  const handleClick = useCallback(
    (lng) => {
      setLang(languageOptions.get(lng));
      i18n.changeLanguage(lng);
      localStorage.setItem("selectedLanguage", lng);
    },
    [i18n, setLang]
  );
  const languagesArray = useMemo(() => [...languageOptions.entries()], []);

  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded="full"
        variant="link"
        cursor="pointer"
        width="40%"
        float="left"
        position="relative"
      >
        <Image mt={3} borderRadius="50%" height="60%" src={lang} width="70%" />
      </MenuButton>
      <MenuList minWidth="25px" marginLeft="-9px">
        {languagesArray.map(([id, src]) => (
          <MenuItem key={id} onClick={() => handleClick(id)}>
            <Image src={src} width="25px" />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageOption;
