import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { google_business } from "../../utils/consts";

const GoogleBusiness = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="google_business_management"
        headerSubtitle="google_business_discover"
      />
      <ServicesBody
        headerText="business_solution"
        headerSubtitle="business_process"
        contentText="business_text"
        servicesStageList={google_business}
      />
      <Footer />
    </>
  );
};

export default GoogleBusiness;
