import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { network_server } from "../../utils/consts";

const NetworkServer = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="network_server"
        headerSubtitle="network_server_discover"
      />
      <ServicesBody
        headerText="network_server_solution"
        headerSubtitle="network_server_process"
        contentText="network_server_text"
        servicesStageList={network_server}
      />
      <Footer />
    </>
  );
};

export default NetworkServer;
