import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Typed from "react-typed";
import { typedTexts } from "../utils/consts";
import { Link } from "react-scroll";
import { BsChevronDoubleDown } from "react-icons/bs";
import Particle from "./Particle";

const Body = () => {
  const { t } = useTranslation();
  const bodyTexts = typedTexts.map((item) => t(item.toString()));

  return (
    <Box marginBottom="100px" width="100vw">
      <Particle />
      <Box bgColor="primaryColor" textColor="whiteColor">
        <Box className="max-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center">
          <Text className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 mt-20">
            {t("main_title")}
          </Text>
          <Box className="flex justify-center items-center">
            <Text className="md:text-4xl sm:text-4xl text-xl font-bold py-4">
              {t("grow_your_business")}
            </Text>
            <Typed
              className="md:text-4xl sm:text-4xl text-xl font-bold pl-2"
              strings={bodyTexts}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </Box>
          <Text className="md:text-2xl font-bold text-gray-500 mt-10">
            {t("fill_offer_form_for_solutions")}
          </Text>
          <Link
            to="what_we_doing"
            smooth
            className="my-6 mx-auto py-3 mb-3 mt-20 cursor-pointer"
          >
            <BsChevronDoubleDown color="whiteColor" size={50} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Body;
