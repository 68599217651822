import React from "react";
import { Container, SimpleGrid, Text, Box, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Process = ({ title, mapList, page }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <Box>
        <Text
          variant={{
            base: "size5",
            sm: "size6",
            md: "size6",
            lg: "size7",
            xl: "size8",
          }}
          textAlign="center"
          color="gray.400"
          _hover={{ color: "buttonColor" }}
        >
          {t(title)}
        </Text>
      </Box>
      <Container marginBottom="100px" maxW="8xl" p={{ base: 5, md: 10 }}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
          placeItems="center"
          spacing={10}
          mb={4}
        >
          {mapList.map(({ number, icon, title, subtitle }, index) => (
            <Box
              key={index}
              bg="gray.100"
              p={6}
              rounded="lg"
              textAlign="center"
              pos="relative"
              height={
                page && { sm: "510px", md: "370px", lg: "300px", xl: "240px" }
              }
            >
              <>
                <Flex
                  p={2}
                  w="40px"
                  color="whiteColor"
                  bgGradient="linear(to-br,baseColor,baseColor )"
                  rounded="full"
                  pos="absolute"
                  left={10}
                  right={0}
                  top="-1.5rem"
                  boxShadow="lg"
                >
                  <Text as="b" ml="3px">
                    {number}
                  </Text>
                </Flex>
                <Flex gap={4}>
                  <Icon
                    color="baseColor"
                    w={16}
                    h={16}
                    as={icon}
                    marginTop="15px"
                  />
                  <Box>
                    <Text
                      textAlign={{ base: "center", sm: "center", md: "left" }}
                      fontWeight="semibold"
                      fontSize="2xl"
                      mt={6}
                    >
                      {t(title)}
                    </Text>
                    <Text
                      textAlign={{ base: "center", sm: "center", md: "left" }}
                      fontSize="md"
                      mt={4}
                    >
                      {t(subtitle)}
                    </Text>
                  </Box>
                </Flex>
              </>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default Process;
