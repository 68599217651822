import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserDetailsFromCookie, isMasterAdmin } from "../utils/helpers";
const RegisterPrivateRouter = () => {
  const userDetails = getUserDetailsFromCookie();
  const isUserMasterAdmin = userDetails
    ? isMasterAdmin(userDetails.role)
    : false;
  return isUserMasterAdmin ? <Outlet /> : <Navigate to="/admin_panel" />;
};
export default RegisterPrivateRouter;
