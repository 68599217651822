import React from "react";
const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.5413020610213!2d29.015791527907346!3d41.07901821306426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab66b733a2d41%3A0xd91e95894531c145!2zTGV2ZW50LCBHw7x2ZXJjaW4gU2suIE5vOjMzLCAzNDMzMCBCZcWfaWt0YcWfL8Swc3RhbmJ1bCwgVMO8cmtpeWU!5e0!3m2!1str!2sus!4v1674740379691!5m2!1str!2sus"
        width="100%"
        height="550"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </div>
  );
};
export { AddressMap };
