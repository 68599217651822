import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { social_media } from "../../utils/consts";

const SocialMedia = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="social_media_management"
        headerSubtitle="social_media_discover"
      />
      <ServicesBody
        headerText="social_media_solution"
        headerSubtitle="social_media_process"
        contentText="social_media_text"
        servicesStageList={social_media}
      />
      <Footer />
    </>
  );
};

export default SocialMedia;
