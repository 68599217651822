import React from "react";
import { FormLabel, Input, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const FormikFormValidation = ({
  values,
  errors,
  handleChange,
  touched,
  id,
  title,
  type,
  place,
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <FormLabel>{t(place)}</FormLabel>
      <Input
        id={id}
        name={title}
        type={type}
        placeholder={t(place)}
        value={values[title]}
        onChange={handleChange}
        bg="gray.100"
        border={0}
        color="gray.500"
        _placeholder={{
          color: "gray.500",
        }}
      />
      {errors[title] && touched[title] && (
        <Text as="sub" color="baseRedColor">
          {errors[title]}
        </Text>
      )}
    </>
  );
};

export default FormikFormValidation;
