import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Heading,
} from "@chakra-ui/react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { aboutData } from "../utils/consts";

const AboutBody = () => {
  const { t } = useTranslation();

  return (
    <Accordion className="m-10" defaultIndex={[0]} allowMultiple>
      {aboutData.map(({ title, info }, index) => (
        <AccordionItem key={index} className="m-5 border-2 text-ml bg-gray-200">
          {({ isExpanded }) => (
            <>
              <Heading>
                <AccordionButton>
                  <Box className="pl-1 pr-5 py-2.5">
                    {isExpanded ? (
                      <FaMinus className="text-base" />
                    ) : (
                      <FaPlus fontSize="18px" />
                    )}
                  </Box>
                  <Box
                    className="text-2xl p-2"
                    as="span"
                    flex="1"
                    textAlign="left"
                  >
                    {t(title)}
                  </Box>
                </AccordionButton>
              </Heading>
              <AccordionPanel className="pb-4" bgColor="whiteColor">
                {t(info)}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default AboutBody;
