import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import Process from "../../components/services_components/ServicesProcess";
import { webProcess, websolution } from "../../utils/consts";

const WebDesign = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="website_design"
        headerSubtitle="web_design_discover"
      />
      <ServicesBody
        headerText="web_solution"
        headerSubtitle="website_design"
        contentText="corporate_who_us"
        servicesStageList={websolution}
      />
      <Process
        title="software_process"
        mapList={webProcess}
        page="cloudsolution"
      />
      <Footer />
    </>
  );
};

export default WebDesign;
