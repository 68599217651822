import React, { useState, useCallback } from "react";
import {
  Link,
  Box,
  Text,
  SimpleGrid,
  Checkbox,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { offerFormValue, offerFormList } from "../utils/consts";
import { OfferFormValidation } from "../utils/validations";
import { Formik, Form } from "formik";
import FormikFormValidation from "./FormikFormValidation";
import { sendModelBoxApi } from "../api/api";
import CustomAlert from "../components/CustomAlert";

const ModalHiddenBox = ({ title, buttons }) => {
  const { t } = useTranslation();
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedButton, setSelectedButton] = useState();
  const handleOnClick = (item) => setSelectedButton(item);
  const handleSubmit = async (values, { resetForm, setFieldValue }) => {
    setSubmissionStatus("");
    const requestData = {
      title: t(selectedButton),
      selectedService: t(title),
      offerFormValue: {
        name: values.name,
        company_name: values.company,
        phone: values.number_value,
        email: values.email,
      },
    };
    setIsSubmitting(true);
    resetForm();
    setFieldValue("message", "");
    const response = await sendModelBoxApi(requestData);
    if (response) {
      setSubmissionStatus("success");
    } else {
      setSubmissionStatus("error");
    }
    setIsSubmitting(false);
  };
  const handleInputChange = useCallback((e, handleChange, id) => {
    if (id === "number_value") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
    handleChange(e);
  }, []);

  return (
    <>
      <Text className="font-semibold mx-5 text-xl text-gary-700">
        {t(title)}
      </Text>
      <Text className="mx-5">{t("select_servise_need")}</Text>
      <SimpleGrid
        margin={5}
        spacing={1}
        templateColumns={{
          base: "repeat(auto-fill, minmax(50%, 1fr))",
          sm: "repeat(auto-fill, minmax(50%, 1fr))",
          md: "repeat(auto-fill, minmax(40%, 1fr))",
          lg: "repeat(auto-fill, minmax(40%, 1fr))",
          xl: "repeat(auto-fill, minmax(20%, 1fr))",
        }}
      >
        {buttons.map((item, index) => (
          <Button
            key={index}
            fontSize="12px"
            whiteSpace="normal"
            onClick={() => handleOnClick(item)}
            borderColor="blueColor"
            borderWidth={item === selectedButton ? "1px" : "0px"}
          >
            {t(item)}
          </Button>
        ))}
      </SimpleGrid>
      <Text className="font-semibold mx-5 text-xl text-gary-700">
        {t("know_you")}
      </Text>
      <Text className="mx-5">{t("fill_form")}</Text>
      <Formik
        initialValues={offerFormValue}
        validationSchema={OfferFormValidation()}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleSubmit, handleChange, touched }) => (
          <Form onSubmit={handleSubmit}>
            <SimpleGrid
              margin={5}
              spacing={2}
              templateColumns={{
                base: "repeat(auto-fill, minmax(50%, 1fr))",
                sm: "repeat(auto-fill, minmax(50%, 1fr))",
                md: "repeat(auto-fill, minmax(40%, 1fr))",
                lg: "repeat(auto-fill, minmax(40%, 1fr))",
                xl: "repeat(auto-fill, minmax(40%, 1fr))",
              }}
            >
              {offerFormList.map(({ id, title, type, place }, index) => (
                <Box key={index}>
                  <FormikFormValidation
                    id={id}
                    title={title}
                    type={type}
                    place={place}
                    values={values}
                    errors={errors}
                    handleChange={(e) => handleInputChange(e, handleChange, id)}
                    touched={touched}
                  />
                </Box>
              ))}
            </SimpleGrid>
            <Checkbox
              id="accept"
              type="checkbox"
              className="mx-5"
              onChange={handleChange}
              required={"required"}
            >
              {t("read_accept")}
            </Checkbox>
            <Link href="/kvkk_policy">
              <Text as="span" color="baseBlueColor" textDecoration="underline">
                {t("kvkk")}
              </Text>
            </Link>
            {errors.accept && touched.accept && (
              <Text
                display="flex"
                marginLeft={5}
                marginTop={2}
                as="sub"
                color="baseRedColor"
              >
                {errors.accept}
              </Text>
            )}
            <Button
              className="m-5 w-36 rounded-2xl"
              variant="solid"
              type="submit"
              fontFamily="heading"
              bgColor="buttonColor"
              color="whiteColor"
              colorScheme="buttonColor"
              marginInline="auto"
              display="block"
              _hover={{
                boxShadow: "xl",
              }}
              isLoading={isSubmitting}
            >
              {isSubmitting ? t("submitting") : t("take_offer")}
            </Button>
            <Stack spacing={4} align="center">
              {submissionStatus && (
                <CustomAlert
                  submissionStatus={submissionStatus}
                  successText={"form_submit"}
                  errorText={"form_failed"}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModalHiddenBox;
