import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { ios_app } from "../../utils/consts";

const IosApp = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="ios_mobil_app"
        headerSubtitle="ios_app_discover"
      />
      <ServicesBody
        headerText="ios_app_solution"
        headerSubtitle="ios_app_process"
        contentText="ios_app_text"
        servicesStageList={ios_app}
      />
      <Footer />
    </>
  );
};

export default IosApp;
