import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import Logo from "./Logo";
import TotalChart from "../assets/TotalChart.png";
import UpdateChart from "../assets/UpdateChart.png";
import NewChart from "../assets/NewChart.png";
import { EditIcon, DeleteIcon, DragHandleIcon } from "@chakra-ui/icons";
import AnnouncementModal from "./AnnouncementModal";
import AdminProfileWindow from "./AdminProfileWindow";
import Pagination from "./AnnouncementsPagination";
import { useTranslation } from "react-i18next";
import { combinedAnnouncements } from "../api/apiService.js";
import { deleteAnnouncement } from "../api/apiService.js";
import { useQuery } from "react-query";
import { perPage } from "../utils/consts.js";
import { useWindowSize } from "../utils/hooks";

const AdminPanel = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [announcements, setAnnouncements] = useState([]);
  const [clicked, setClicked] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const windowSize = useWindowSize();

  const handleEdit = useCallback((announcement) => {
    setClicked(announcement);
    setIsModalOpen(true);
  }, []);

  const { data, isLoading, refetch } = useQuery(
    ["announcements", currentPage, perPage],
    () => combinedAnnouncements(currentPage, perPage),
    {
      initialData: { data: { AnnouncementsList: [] } },
      onSuccess: (responseData) => {
        setAnnouncements(responseData.data.AnnouncementsList);
      },
      onError: () => {
        toast.error(t("error_message"));
      },
    }
  );

  const handleDelete = useCallback(
    async (announcement) => {
      try {
        await deleteAnnouncement(announcement.id);
        setAnnouncements((prevAnnouncements) =>
          prevAnnouncements.filter((item) => item.id !== announcement.id)
        );
        if (
          announcements.length === 1 &&
          currentPage > 1 &&
          currentPage === Math.ceil(data.totalAnnouncements / perPage)
        ) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
      } catch (error) {
        toast.error(t("delete_error"));
      }
    },
    [announcements, currentPage, data.totalAnnouncements]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      if (
        newPage >= 1 &&
        newPage <= Math.ceil(data.data.totalAnnouncements / perPage)
      ) {
        setCurrentPage(newPage);
      }
    },
    [data, perPage]
  );

  const totalPages = useMemo(
    () => Math.ceil(data.totalAnnouncements / perPage),
    [data.totalAnnouncements, perPage]
  );

  return (
    <Flex bg="panelBgColor" minHeight="100vh" flexDirection="column">
      <Flex
        bg="whiteColor"
        alignItems="center"
        justifyContent="space-between"
        py={2}
        px={4}
        flexWrap="wrap"
        boxShadow="sm"
      >
        <Box flex="1" textAlign="center">
          <Logo />
        </Box>
        <Box flex="1" textAlign="center">
          <Text
            fontSize={["xl", "lg", "md"]}
            fontWeight="bold"
            my={2}
            display={{ base: "none", sm: "none", md: "block" }}
          >
            {t("dashboard")}
          </Text>
        </Box>
        <Box flex="1" textAlign="right">
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton
              icon={<DragHandleIcon />}
              onClick={() => setIsProfileOpen(true)}
              cursor="pointer"
            />
          </Box>
        </Box>
      </Flex>
      <Grid
        mt="20px"
        templateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        justifyItems="center"
        gap={4}
      >
        <GridItem margin="10px" backgroundColor="whiteColor">
          <Flex flexDirection={{ sm: "column", md: "row" }} alignItems="center">
            <Box flex="1" padding={8}>
              <Text fontSize="xl">{t("total_announcements")}</Text>
              <Text fontSize="2xl">{data.data?.totalAnnouncements}</Text>
            </Box>
            <Box mt={["4", 0]}>
              <Image width="150px" src={TotalChart} />
            </Box>
          </Flex>
        </GridItem>
        <GridItem margin="10px" backgroundColor="whiteColor">
          <Flex flexDirection={{ sm: "column", md: "row" }} alignItems="center">
            <Box flex="1" padding={8}>
              <Text fontSize="xl">{t("updated_announcements")}</Text>
              <Text fontSize="2xl">{data.data?.updatedAnnouncementsCount}</Text>
            </Box>
            <Box mt={["4", 0]}>
              <Image width="150px" src={UpdateChart} />
            </Box>
          </Flex>
        </GridItem>
        <GridItem
          colSpan={[1, 1, 2, 1]}
          margin="10px"
          backgroundColor="whiteColor"
        >
          <Flex flexDirection={{ sm: "column", md: "row" }} alignItems="center">
            <Box flex="1" padding={8}>
              <Text fontSize="xl">{t("new_announcements")}</Text>
              <Text fontSize="2xl">{data.data?.newAnnouncementsCount}</Text>
            </Box>
            <Box mt={["4", 0]}>
              <Image width="150px" src={NewChart} />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      <Box
        mb="70px"
        mt="50px"
        width="90%"
        bg="whiteColor"
        padding={7}
        alignSelf="center"
      >
        <Flex
          mt="20px"
          mx="auto"
          maxWidth="90%"
          bg="whiteColor"
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent="space-between"
          padding={3}
          flexDir={{ base: "column", sm: "row" }}
        >
          <Text fontSize="xl" mt={5} ml="4">
            {t("general_announcements")}
          </Text>
          <Button
            ml={8}
            mt={5}
            bg="baseColor"
            color="whiteColor"
            onClick={() => setIsModalOpen(true)}
            isLoading={isLoading}
          >
            {t("add_new")}
          </Button>
        </Flex>
        <Box justify="center" align="center" mt="50px" overflow="auto">
          <Table>
            <Thead bg="panelBgColor" >
              <Tr>
                <Th width="15%">{t("date")}</Th>
                <Th width="100%">{t("title")}</Th>
                <Th width="100%">{t("actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {announcements?.map((announcement, index) => (
                <Tr key={index}>
                  <Td>{announcement.date}</Td>
                  <Td
                    style={{
                      maxWidth: "400px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {announcement.title}
                  </Td>
                  <Td textAlign="right">
                    <Flex direction={windowSize >= 515 ? "row" : "column"}>
                      <IconButton
                        size="sm"
                        bg="baseColor"
                        color="whiteColor"
                        icon={<EditIcon />}
                        mr={windowSize >= 515 ? 2 : 0}
                        mb={windowSize < 515 ? 2 : 0}
                        onClick={() => handleEdit(announcement)}
                        isLoading={isLoading}
                      />
                      <IconButton
                        size="sm"
                        bg="logoRedColor"
                        color="whiteColor"
                        icon={<DeleteIcon />}
                        onClick={() => handleDelete(announcement)}
                        isLoading={isLoading}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
              <AnnouncementModal
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                  setClicked(undefined);
                  refetch();
                }}
                updateData={setAnnouncements}
                editData={clicked}
              />
            </Tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Box>
      </Box>
      <AdminProfileWindow
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
      />
    </Flex>
  );
};

export default AdminPanel;
