import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAdminModalPassword } from "../utils/hooks.js";
import { comparePasswordsValidation } from "../utils/validations.js";

const AdminModalPassword = ({ isOpen, onClose, userEmail }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { mutateAsync, isLoading } = useAdminModalPassword();

  const handleSubmit = async (values) => {
    try {
      const data = await mutateAsync({
        userEmail,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      if (data.success) {
        toast({
          title: t("success"),
          description: t("password_changed_successful"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: t("error"),
          description: data.message || t("password_changed_error"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title:  t("error"),
        description: t("error_message"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("changed_password")}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            repeatNewPassword: "",
          }}
          validationSchema={comparePasswordsValidation(t)}
          onSubmit={handleSubmit}
        >
          <Form>
            <ModalBody>
              <Text>{t("email")}</Text>
              <div>{userEmail}</div>
              <Text>{t("current_password")}</Text>
              <Field
                type="password"
                name="currentPassword"
                as={Input}
              />
              <ErrorMessage name="currentPassword" component="div" />
              <Text>{t("new_password")}</Text>
              <Field
                type="password"
                name="newPassword"
                as={Input}
              />
              <ErrorMessage name="newPassword" component="div" />
              <Text>Yeni Şifre Tekrar :</Text>
              <Field
                type="password"
                name="repeatNewPassword"
                as={Input}
              />
              <ErrorMessage name="repeatNewPassword" component="div" />
            </ModalBody>
            <ModalFooter>
              <Button
                bg="baseTealColor"
                color="whiteColor"
                type="submit"
                isLoading={isLoading}
              >
                {t("refresh")}
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AdminModalPassword;
