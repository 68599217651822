import React from "react";
import BodyBox from "../components/BodyBox";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactBody from "../components/ContactBody";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <BodyBox title="contact" bgColor="primaryColor" textColor="whiteColor" />
      <ContactBody />
      <Footer />
    </>
  );
};

export default ContactUs;
