import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { ssl_service } from "../../utils/consts";

const SslService = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="ssl_certificate_services"
        headerSubtitle="ssl_certificate_discover"
      />
      <ServicesBody
        headerText="ssl_certificate"
        headerSubtitle="ssl_certificate_process"
        contentText="ssl_text"
        servicesStageList={ssl_service}
      />
      <Footer />
    </>
  );
};

export default SslService;
