import React from "react";
import BodyBox from "../components/BodyBox";
import AboutBody from "../components/AboutBody";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const About = () => {
  return (
    <>
      <Navbar />
      <BodyBox title="about_us" bgColor="primaryColor" textColor="whiteColor" />
      <AboutBody />
      <Footer />
    </>
  );
};

export default About;
