import React from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const CustomAlert = ({ submissionStatus, successText, errorText }) => {
  const { t } = useTranslation();

  return (
    <Alert
      status={submissionStatus}
      w="250px"
      borderRadius="2xl"
      justifyContent="center"
      h="40px"
      marginTop="20px"
    >
      <AlertIcon
        as={submissionStatus ? CheckCircleIcon : WarningTwoIcon}
        mr={2}
      />
      {submissionStatus ? t(successText) : t(errorText)}
    </Alert>
  );
};

export default CustomAlert;
