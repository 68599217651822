import { IMG_SRC_REGEX,masterAdmin,admin } from "../utils/consts";
import { getCookie, setCookie,removeCookie } from "../components/Token";

export function getCurrentYear() {
  const currentYear = new Date().getFullYear();
  return currentYear;
}
export function isAdmin(role) {
  return role === masterAdmin || role === admin;
}

export function isMasterAdmin(role) {
  return role === masterAdmin;
}

export function extractImageFromHTML(htmlContent) {
  const imgMatch = htmlContent.match(IMG_SRC_REGEX);
  return imgMatch ? imgMatch[1] : null;
}

export const saveAuthUserToCookie = (userDetails) => {
  const authUserInfo = JSON.stringify({
    email: userDetails.email,
    role: userDetails.role,
    token: userDetails.token,
  });
  setCookie("authUser", authUserInfo);
};

export const getUserDetailsFromCookie = () => {
  const authUserCookie = getCookie("authUser");
  return authUserCookie ? JSON.parse(authUserCookie) : null;
};

export const removeAllCookies = () => {

  const cookiesToRemove = ["authToken", "authUser"];
  cookiesToRemove.forEach(cookieName => removeCookie(cookieName));
};
