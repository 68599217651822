import React from "react";
import { Flex } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import logo from "../assets/logo.png";

const Logo = ({ logoWidthStyle }) => {
  return (
    <Flex align="center">
      <Image
        src={logo}
        w={
          logoWidthStyle ?? {
            base: "100px",
            sm: "150px",
            md: "200px",
            lg: "200px",
            xl: "200px",
          }
        }
        alt="yukatechlogo"
        marginLeft="0"
      />
    </Flex>
  );
};
export default Logo;
