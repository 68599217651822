import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ServicesBody from "../../components/services_components/ServicesBody";
import ServicesHeader from "../../components/services_components/ServicesHeader";
import { email_solution } from "../../utils/consts";

const EmailSolution = () => {
  return (
    <>
      <Navbar />
      <ServicesHeader
        headerText="email_solutions"
        headerSubtitle="email_solutions_discover"
      />
      <ServicesBody
        headerText="email_solution"
        headerSubtitle="email_process"
        contentText="email_text"
        servicesStageList={email_solution}
      />
      <Footer />
    </>
  );
};

export default EmailSolution;
