import React, { useMemo } from "react";
import {
  Text,
  Flex,
  Box,
  Image,
  Container,
  Stack,
  Heading,
  List,
  ListItem,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { servicesBodyImages } from "../../utils/consts";

const ServicesBody = ({
  headerText,
  headerSubtitle,
  contentText,
  servicesStageList,
}) => {
  const { t } = useTranslation();

  const matchingService = useMemo(
    () => servicesBodyImages.find((item) => item.pageName.includes(headerText)),
    [servicesBodyImages, headerText]
  );

  return (
    <div id="body">
      <Container maxW="7xl">
        <Stack
          alignItems="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading lineHeight={1.1} fontWeight={600}>
              <Text
                as="span"
                position="relative"
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "red.400",
                  zIndex: -1,
                }}
              >
                {t(headerText)}
              </Text>
              <br />
              <Text as="span" color="red.400">
                {t(headerSubtitle)}
              </Text>
            </Heading>
            <Text color="gray.500">{t(contentText)}</Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
            />
          </Stack>
          <Flex
            flex={1}
            justify-content="center"
            alignItems="center"
            position="relative"
            w="full"
          >
            <Box
              position="relative"
              height="auto"
              rounded="2xl"
              boxShadow="2xl"
              width="full"
              overflow="hidden"
            >
              <Image
                fit="cover"
                alignItems="center"
                w="100%"
                h="100%"
                src={matchingService.image[0]?.servicesimagefirst}
                style={{
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
      <Container maxW="7xl">
        <Stack
          alignItems="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            flex={1}
            justify-content="center"
            alignItems="center"
            position="relative"
            w="full"
          >
            <Box
              position="relative"
              height="auto"
              rounded="2xl"
              boxShadow="2xl"
              width="full"
              overflow="hidden"
            >
              <Image
                fit="cover"
                alignItems="center"
                w="100%"
                h="100%"
                src={matchingService.image[1]?.servicesimagesecond}
                style={{
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </Box>
          </Flex>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text
                as="span"
                position="relative"
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "red.400",
                  zIndex: -1,
                }}
              >
                {t("yukatech_dev")}
              </Text>
              <br />
              <Text as="span" color="red.400">
                {t("contact_us")}
              </Text>
            </Heading>
            {servicesStageList.map(({ content, icon }, index) => (
              <List key={index}>
                <ListItem>
                  <Icon
                    as={icon}
                    color="green.500"
                    font-size="1.5em"
                    margin="6px"
                  />
                  {t(content)}
                </ListItem>
              </List>
            ))}
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
            />
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default ServicesBody;
