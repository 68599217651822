import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const CarrerFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    name: Yup.string().required(t("name_error")),
    email: Yup.string().email().required(t("email_error")),
    phone: Yup.number().required(t("number_error")),
    position: Yup.string().required(t("position_error")),
    description: Yup.string().required(t("write_your_message")),
  });
};

export const OfferFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    name: Yup.string().required(t("name_error")),
    email: Yup.string().email().required(t("email_error")),
    company: Yup.string().required(t("company_error")),
    number_value: Yup.number().required(t("number_error")),
    accept: Yup.boolean()
      .required(t("accept_error"))
      .oneOf([true], t("accept_error")),
  });
};

export const ContactFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    name: Yup.string().required(t("name_error")),
    email: Yup.string().email().required(t("email_error")),
    phone: Yup.number().required(t("number_error")),
    message: Yup.string().required(t("message_error")),
  });
};

export const PasswordResetFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    newPassword: Yup.string()
      .min(8, t("password_min_error")) 
      .matches(/[a-z]/, t("password_lowercase_error")) 
      .matches(/[A-Z]/, t("password_uppercase_error")) 
      .matches(/[0-9]/, t("password_number_error")) 
      .required(t("password_required_error")), 
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t("password_match_error"))
      .required(t("confirm_password_required_error")), 
  });
};

export const validationSchema = (t) => Yup.object({
  email: Yup.string()
    .email(t("email_error"))
    .required(t("email_require")),
  password: Yup.string()
    .min(5, t("password_min"))
    .matches(/[a-z]/, t("password_lowercase_error"))
    .required(t("password_required_error")),
});

export const comparePasswordsValidation = (t) => {
  return Yup.object({
  currentPassword: Yup.string()
    .required(t("current_password_required")),
  newPassword: Yup.string()
    .required(t("new_password_required")),
  repeatNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], (t("compare_password")))
    .required(t("password_confirmation_required")),
});
};
